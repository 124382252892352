<template>
    <el-dialog title="审核记录" class="dialogTable" width="90%" :close-on-click-modal="false" append-to-body :visible.sync="visible">
        <review-steps class="m_b2" :stepsList="stepsList" :steps="steps" :disabled="disabled"></review-steps>
        <el-form size="small" :inline="true" ref="searchForm" :model="searchForm"
                 label-width="100px" @keyup.enter.native="search(1)">
            <el-form-item prop="createBy" label="审核人：">
                <el-select v-model="searchForm.createBy" filterable placeholder="请选择" clearable>
                    <el-option v-for="item in auditUserList" :key="item.userId" :label="item.name"
                               :value="item.userId"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="processResult" label="审核结果：">
                <el-select v-model="searchForm.processResult" filterable placeholder="请选择" clearable>
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('audit_state').filter(v => v.value=='2' || v.value=='3')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                class="table" ref="multipleTable">
            <el-table-column type="index" label="序号" show-overflow-tooltip></el-table-column>
            <el-table-column prop="processResult" label="审核结果" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{$dictUtils.getDictLabel("audit_state", scope.row.processResult ,'')}}
                </template>
            </el-table-column>
            <el-table-column prop="createUserName" label="审核人" show-overflow-tooltip></el-table-column>
            <el-table-column prop="createDate" label="审核时间" show-overflow-tooltip></el-table-column>
            <el-table-column prop="processName" label="审核操作" show-overflow-tooltip></el-table-column>
            <el-table-column prop="borrowTime" label="借阅时间" show-overflow-tooltip></el-table-column>
            <el-table-column prop="deadline" label="期限" show-overflow-tooltip></el-table-column>
            <el-table-column prop="processOpinion" label="反馈详情" show-overflow-tooltip></el-table-column>
            <el-table-column prop="submitByName" label="提交人" show-overflow-tooltip></el-table-column>
            <el-table-column prop="submitTime" label="提交时间" show-overflow-tooltip></el-table-column>
        </el-table>
        <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="searchForm.current"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="searchForm.size"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
    </el-dialog>
</template>

<script>
    import ReviewSteps from "@/views/modules/collection/accounts/module/reviewSteps.vue";
    export default {
        components: {ReviewSteps},
        data() {
            return {
                visible: false,
                loading: false,
                searchForm: {
                    size: 10,
                    current: 1,
                    borrowId: '',
                    createBy: '',
                    processResult: '',
                },
                auditUserList: [],
                dataList: [],
                total: 0,
                borrowType: '',
                // 审核步骤
                steps:0,
                disabled:false,
                stepsList:[],
            }
        },
        methods: {
            // type  0电子借阅，1阅览，2借出，3内部利用
            init (id, type) {
                this.borrowType = type
                this.searchForm.borrowId = id
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['searchForm'].resetFields()
                    this.search(1)
                    this.getReviewSteps()
                })
            },

            // 获取审核步骤
            getReviewSteps(){
                this.$axios(this.api.record.getByDataId + '/' + this.searchForm.borrowId).then((data) => {
                    if (data && data.status) {
                        this.stepsList = data.data
                        this.steps = this.stepsList.length
                        let firstDta = 0
                        //判断是否全是未开始的状态
                        this.disabled = this.stepsList[0].complement == 1
                        this.stepsList.forEach((item,index)=>{
                            if (item.complement == 1 && !firstDta){ //找到当前进行到第几步
                                firstDta++
                                this.steps = index
                                if (this.stepsList[index - 1].revdOperate == 2){ //判断当前进行的步骤是否被驳回
                                    this.$set(item,'status','wait')
                                }
                            } else {
                                this.$set(item,'status','')
                            }
                        })
                    }
                });
            },
            search(type) {
                this.getAuditByType()
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                this.$axios(this.api.record.getByBorrowId, this.searchForm, 'get').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 获取审核人
            getAuditByType() {
                this.$axios(this.api.record.getAuditByList, this.searchForm, 'get').then((res) => {
                    if (res.status) {
                        this.auditUserList = res.data
                    }
                })
            },
            // 重置
            reset() {
                this.$refs.searchForm.resetFields();
                this.search(1)
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        },
    }
</script>

<style scoped>

</style>
